.locale-switcher-outer,
.guidebook-status-outer {
  background: #e0e0e0;
  padding: 8px;
  position: fixed;
  left: 0;
  top: 0;
  width: 100vw;
  height: 64px;
  z-index: 15;

  .locale-switcher-inner,
  .guidebook-status-inner {
    position: relative;
    height: 48px;
  }
  .preview-button {
    vertical-align: middle;
  }
  .locale-buttons {
    width: calc(100% - 48px);
    white-space: nowrap;
    overflow: auto;
    display: inline-block;
  }
  .language-button {
    text-transform: none;
    &.active {
      color: rgba(0, 0, 0, 0.54);
    }
  }
  .close-locales {
    color: rgba(0, 0, 0, 0.54);
  }
}

//offset for locales menu
.md-locale-offset {
  padding-top: 64px;
}
.splash-screen.desktop .slick-slider.slider-locale-offset {
  margin-top: 104px;
}
.md-toolbar--fixed.md-toolbar--locale-offset,
.drawer.md-drawer--locale-offset {
  top: 64px;
}
.md-btn--icon.close-locales {
  position: absolute;
}

@media screen and (min-width: 1025px) {
  .locale-switcher-outer,
  .guidebook-status-outer {
    height: 48px;
    .locale-switcher-inner,
    .guidebook-status-inner {
      height: 32px;
    }
    .close-locales {
      height: 36px;
      width: 36px;
      padding: 0;
    }
  }

  .md-locale-offset {
    padding-top: 48px;
  }
  .splash-screen.desktop .slick-slider.slider-locale-offset {
    margin-top: 88px;
  }
  .md-toolbar--fixed.md-toolbar--locale-offset,
  .drawer.md-drawer--locale-offset {
    top: 48px;
  }
}
